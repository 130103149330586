import React from 'react';

export const ReactLazyPreload = importStatement => {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

let preloaded = false;
const componentsToPreload = [];

export const ReactLazyAndPreload = importStatement => {
  const Component = ReactLazyPreload(importStatement);
  if (preloaded) {
    Component.preload();
  }
  else {
    componentsToPreload.push(Component);
    if (componentsToPreload.length == 1) {
      window.addEventListener('load',
        () => setTimeout(() => {
          componentsToPreload.forEach(component => component.preload());
        }, 500));
    }
  };
  return Component;
};