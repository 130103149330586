import React, { useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ArticleIcon from '@mui/icons-material/Article';
import { Avatar } from '@mui/material';

const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  // color: '#bdbdbd',
  color: '#444444',
  transition: 'border .3s ease-in-out'
};

const activeStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const FileDropzone = React.forwardRef((props, ref) => {
  const [files, setFiles] = useState([]);
  const maxFiles = props.maxFiles || 0;

  useImperativeHandle(ref, () => ({
    files: () => {
      return files;
    },
  }));

  useEffect(() => {
    if (maxFiles && files.length > maxFiles) {
      setFiles(files.slice(0, maxFiles));
    }

  }, [files]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file)
    })));
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    onDrop,
    accept: 'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/acrobat, application/x-pdf, applications/vnd.pdf, text/pdf, text/x-pdf'
  });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  function removeFile(index) {
    files.splice(index, 1);
    setFiles([...files]);
  }

  const thumbs = files.map((file, index) => (
    <div key={file.name} style={{ textAlign: 'center' }} onClick={() => removeFile(index)}>
      <ArticleIcon sx={{ width: 48, height: 48, color: 'primary.main' }} /><br />
      {/* <img
        src={file.preview}
        alt={file.name}
      /> */}
      {file.name}
    </div>
  ));

  // clean up
  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section>
      {maxFiles && thumbs.length >= maxFiles ? null : <div {...getRootProps({ style })}>
        <input {...getInputProps()} id={props.id} name={props.name} />
        <div>{props.dragText || 'Drag and drop your images here.'}</div>
      </div>}
      <aside>
        {thumbs}
      </aside>
    </section>
  );
});

export default FileDropzone;