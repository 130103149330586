import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { HashRouter as Router, Link, LinkProps } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import WorkIcon from '@mui/icons-material/Work';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { COMPANIES } from '../../shared/conf/companies';

import { Autocomplete, Chip, FormControl, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
const { MAX_WIDTH } = require('../../env/env');

const PositionForm = React.forwardRef((props, ref) => {
  const roleRef = React.useRef();
  const [authorized, setAuthorized] = React.useState(null);
  const [sponsorship, setSponsorship] = React.useState(null);
  const [agree, setAgree] = React.useState(false);

  const [locationError, setLocationError] = React.useState();
  const [companiesError, setCompaniesError] = React.useState();
  const [roleError, setRoleError] = React.useState();
  const [authorizedError, setAuthorizedError] = React.useState();
  const [sponsorshipError, setSponsorshipError] = React.useState();
  const [agreeError, setAgreeError] = React.useState();

  const [locations, setLocations] = React.useState(['Remote', 'San Francisco, CA']);
  const [companies, setCompanies] = React.useState([{ name: 'Instacart', id: 1 }]);

  React.useImperativeHandle(ref, () => ({
    additionalValues: () => {
      return { companies: JSON.stringify(companies.map(company => company.id)), locations };
    },
    validate: () => {
      let error = false;
      if (!locations.length) {
        error = true;
        setLocationError('Please enter your preferr location(s).');
      }
      if (!companies.length) {
        error = true;
        setCompaniesError('Please select a company to be referred to.');
      }
      if (!roleRef.current.value) {
        error = true;
        setRoleError('Please enter your preferred role.');
      }
      if (!authorized) {
        error = true;
        setAuthorizedError('Please let us know whether you are authorized.');
      }
      if (!sponsorship) {
        error = true;
        setSponsorshipError('Please let us know whether you will need sponsorship.');
      }
      if (!agree) {
        error = true;
        setAgreeError('You must agree to continue.');
      }
      return !error;
    },
    // hiding the value e.g. react-stripe-elements
  }));


  return <Container component="main"
    maxWidth={MAX_WIDTH} style={props.style}>
    <Box
      sx={{
        // marginTop: 8 ,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar
        sx={{ m: 1, bgcolor: 'primary.main' }}
      >
        <WorkIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Work I'm Interested In
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              freeSolo
              onChange={(event, newValue) => {
                setLocations(newValue);
              }}
              value={locations}
              // defaultValue={}
              multiple
              options={[
                // { label: 'Remote', id: 'Remote' }, { label: 'San Francisco, CA', id: 'San Francisco, CA' }
              ]}
              getOptionLabel={(option) => option.label}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    clickable
                  />
                ))
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  error={!!locationError}
                  helperText={locationError}
                  placeholder={"Your City"}
                  label="Where would you like to work?"
                  variant="standard"
                  // fullWidth
                  {...params} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              id="companies"
              name="companies"
              disablePortal
              onChange={(event, newValue) => {
                setCompanies(newValue);
              }}
              value={companies}
              multiple
              isOptionEqualToValue={(a, b) => a.id == b.id}
              options={COMPANIES}
              getOptionLabel={(option) => option.name}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.name}
                    {...getTagProps({ index })}
                    clickable
                  />
                ))
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  error={!!companiesError}
                  helperText={companiesError}
                  placeholder={"Companies"}
                  label="What companies would you like to be referred to?"
                  variant="standard"
                  // fullWidth
                  {...params} />
              )}
            />

          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              freeSolo
              onChange={() => setRoleError(null)}
              defaultValue={'Software Engineer'}
              options={[
                "Data Scientist",
                "Engineering Manager",
                "Mobile Engineer",
                "Product Manager",
                "Software Engineer",
                "User Experience Designer",
              ]}
              getOptionLabel={(option) => {
                // if (option.length) {
                //   return option[0];
                // }
                return option;
              }}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option}
                    {...getTagProps({ index })}
                    clickable
                  />
                ))
              }
              fullWidth
              renderInput={(params) => (
                <TextField
                  id="position"
                  name="position"
                  error={!!roleError}
                  helperText={roleError}
                  placeholder={"You can enter a role not on the list"}
                  label="What type of role are you interested in? You can enter any role, even if it's not on the list."
                  variant="standard"
                  // fullWidth
                  inputRef={roleRef}
                  {...params} />
              )}
            />

          </Grid>

          <Grid item xs={12}>
            <FormControl
              error={!!authorizedError}
              // helperText={authorizedError}
              onChange={() => setAuthorizedError(null)}
            >
              <FormLabel id="authorized-label">Are you authorized to work in the country you're interested in being referred to?</FormLabel>
              <RadioGroup
                id="authorized"
                aria-labelledby="authorized-label"
                name="authorized"
                onChange={(event, newValue) => {
                  setAuthorized(newValue);

                }}
                value={authorized}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              error={!!sponsorshipError}
              // helperText={sponsorshipError}
              onChange={() => setSponsorshipError(null)}
            >
              <FormLabel id="sponsorship-label">Will you now or in the future require sponsorship to continue or extend your current work authorization status?</FormLabel>
              <RadioGroup
                id="sponsorship"
                aria-labelledby="sponsorship-label"
                name="sponsorship"
                onChange={(event, newValue) => {
                  setSponsorship(newValue);

                }}
                value={sponsorship}
              >
                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="notes"
              name="notes"
              label="Anything else we should know?"
              placeholder='What else would you like the people referring you to know?'
              multiline
              fullWidth
            />          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox id="allowOtherCompanies" name="allowOtherCompanies" value="allowOtherCompanies" color="primary" defaultChecked />}
              label="Please allow other companies to contact me if they think I'd be a good fit."
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox id="sendAnnouncements" name="sendAnnouncements" value="sendAnnouncements" color="primary" defaultChecked />}
              label="Please let me know when new companies are added and other interesting announcements."
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              // className={agreeError ? 'Mui-error' : null}
              style={{ color: agreeError ? '#d32f2f' : null }}
              // helperText={agreeError}
              control={<Checkbox
                checked={agree}
                onChange={(event, newValue) => {
                  setAgreeError(null);
                  setAgree(newValue);
                }}
                inputProps={{ 'aria-label': 'controlled' }}
                id="agree" name="agree" value="agree" color="primary" />}
              label={<Typography >

                I agree to the  <Link to="/termsOfService"> Terms of Service</Link> and <Link to="/privacyPolicy">Privacy Policy</Link>.                     </Typography>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Container>;
});
export default PositionForm;