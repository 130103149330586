import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import PersonIcon from '@mui/icons-material/Person'; import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import FileDropzone from '../Components/FileDropzone';
const { MAX_WIDTH } = require('../../env/env');
const { FLAG_ASK_FOR_PASSWORD } = require('../../shared/flags/flags');

const PersonalInfoForm = React.forwardRef((props, ref) => {
  const firstNameRef = React.useRef();
  const lastNameRef = React.useRef();
  const emailRef = React.useRef();
  const passwordRef = React.useRef();
  const fileRef = React.useRef();

  const [firstNameError, setFirstNameError] = React.useState();
  const [lastNameError, setLastNameError] = React.useState();
  const [emailError, setEmailError] = React.useState();
  const [passwordError, setPasswordError] = React.useState();
  const [fileError, setFileError] = React.useState();

  React.useImperativeHandle(ref, () => ({
    validate: () => {
      let error = false;
      if (!firstNameRef.current.value) {
        error = true;
        setFirstNameError('Please enter your first name.');
      }
      if (!lastNameRef.current.value) {
        error = true;
        setLastNameError('Please enter your last name.');
      }
      if (!emailRef.current.value) {
        error = true;
        setEmailError('Please enter your email address.');
      }
      if (FLAG_ASK_FOR_PASSWORD && !passwordRef.current.value) {
        error = true;
        setPasswordError('Please enter a password.');
      }
      if (!fileRef.current.files().length) {
        error = true;
        setFileError('Please add your resume.');
      }
      return !error;
    },
    additionalValues: () => {
      return { resume: fileRef.current.files().length ? fileRef.current.files()[0] : null };
    }
    // hiding the value e.g. react-stripe-elements
  }));

  return <Container component="main" maxWidth={MAX_WIDTH} style={props.style}>
    <Box
      sx={{
        // marginTop: 8 ,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar
        sx={{ m: 1, bgcolor: 'primary.main' }}
      >
        <PersonIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        About me
      </Typography>
      {/* <Grid container justifyContent="center">
        <Grid item>
        </Grid>
      </Grid> */}
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              autoComplete="given-name"
              name="firstName"
              required
              fullWidth
              id="firstName"
              label="First Name"
              autoFocus
              inputRef={firstNameRef}
              error={!!firstNameError}
              helperText={firstNameError}
              onChange={() => setFirstNameError(null)}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              autoComplete="family-name"
              inputRef={lastNameRef}
              error={!!lastNameError}
              helperText={lastNameError}
              onChange={() => setLastNameError(null)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              inputRef={emailRef}
              error={!!emailError}
              helperText={emailError}
              onChange={() => setEmailError(null)}
            />
          </Grid>
          {FLAG_ASK_FOR_PASSWORD ? <Grid item xs={12}>
            <TextField
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              inputRef={passwordRef}
              error={!!passwordError}
              helperText={passwordError}
              onChange={() => setPasswordError(null)}
            />
          </Grid> : null}
          <Grid item xs={12}>
            <FileDropzone ref={fileRef} dragText={fileError || 'Drag and drop your resume here.'} maxFiles={1} id="resume" name="resume" />
          </Grid>
        </Grid>
      </Box>
    </Box>
  </Container>;
});
export default PersonalInfoForm;