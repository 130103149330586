import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PersonalInfoForm from './PersonalInfoForm';
import PositionForm from './PositionForm';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Container, MobileStepper, Toolbar } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useTheme } from '@mui/material/styles';
import Confirmation from './Confirmation';
import Navbar from '../Navbars/Navbar';
import Footer from '../Footers/Footer';

const { MAX_WIDTH, DEV } = require('../../env/env');

// const steps = ['Tell me about yourself', 'Where do you want to work?', 'Confirm'];

const steps = ['about-me', 'seeking'];
const reverseSteps = new Map([['about-me', 0], ['seeking', 1,], ['confirm', 2], ['complete', 3]]);


export default function ReferralForm() {
  const referralFormRef = React.useRef();
  const personalInfoRef = React.useRef();
  const positionRef = React.useRef();
  const { step } = useParams();
  const activeStep = Number(step) || 0;
  const [disableNext, setDisableNext] = React.useState(false);
  function setActiveStep(step, replace) {
    navigate('/get-referred/' + step, { replace });
  }
  // const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const navigate = useNavigate();

  const isStepOptional = (step) => {
    return false;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  React.useEffect(() => {
    function prevStepValid() {
      switch (activeStep) {
        case 1:
          return personalInfoRef.current.validate();
        default:
          return true;
      }
    }
    if (!prevStepValid()) {
      setActiveStep(activeStep - 1, true);
    }
  }, []);


  async function sendForm() {
    const formData = new FormData(referralFormRef.current);
    const personalValues = personalInfoRef.current.additionalValues();
    for (const key of Object.keys(personalValues)) {
      formData.set(key, personalValues[key]);
    }
    const positionValues = positionRef.current.additionalValues();
    for (const key of Object.keys(positionValues)) {
      formData.set(key, positionValues[key]);
    }
    await fetch('/requestReferral', { method: "POST", body: formData });
  }

  const handleNext = async () => {
    if (activeStep == 0 && !personalInfoRef.current.validate()) {
      return;
    }
    if (activeStep == 1 && !positionRef.current.validate()) {
      return;
    }

    if (activeStep == steps.length - 1) {
      setDisableNext(true);
      try {
        await sendForm();
        setActiveStep(activeStep + 1);
      } catch (e) {

      } finally {
        setDisableNext(false);
      }
    } else {
      setActiveStep(activeStep + 1);
    }

  };

  const handleBack = () => {
    if (activeStep == 0) {
      navigate('/');
    }
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const theme = useTheme();
  return (
    <>

      <Navbar />
      <Box sx={{ width: '100%' }}>
        <Box sx={{ padding: 5 }}>
          <React.Fragment>
            <form id="referralForm" enctype="multipart/form-data" method="post" ref={referralFormRef}>
              <PersonalInfoForm ref={personalInfoRef} style={{ display: activeStep == 0 ? 'block' : 'none' }} />
              <PositionForm ref={positionRef} style={{ display: activeStep == 1 ? 'block' : 'none' }} />
              <Confirmation style={{ display: activeStep == 2 ? 'block' : 'none' }} />
            </form>

          </React.Fragment>
          <Container  >
            <Box
              sx={{

                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {activeStep >= steps.length ? null : <Container maxWidth={MAX_WIDTH}><MobileStepper
                variant="progress"
                steps={steps.length}
                position="static"
                activeStep={activeStep}
                sx={{
                  width: '100%',
                  // maxWidth: 800,
                  flexGrow: 1
                }}
                nextButton={
                  <Button size="small" onClick={handleNext} disabled={disableNext}>
                    {activeStep === steps.length - 1 ? 'SEND' : 'Next'}
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowLeft />
                    ) : (
                      <KeyboardArrowRight />
                    )}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === 'rtl' ? (
                      <KeyboardArrowRight />
                    ) : (
                      <KeyboardArrowLeft />
                    )}
                    Back
                  </Button>
                }
              /></Container>}
              {DEV ? <Button size="small" onClick={sendForm}>
                Send form
              </Button> : null
              }
            </Box>
          </Container>
        </Box>
      </Box >
      <Footer />
    </>
  );
}
