import { Button, Container, Paper, Stack, Toolbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { MAX_WIDTH } from '../../env/base.env';
import { db } from '../../lib/db/db';

function gtag() { window.dataLayer.push(arguments); }


function onGDPRAccepted() {
  // // Matomo
  // var _paq = window._paq = window._paq || [];
  // /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  // _paq.push(['trackPageView']);
  // _paq.push(['enableLinkTracking']);
  // (function () {
  //   var u = "//anltcs.lvlnxt.com/matomo/";
  //   _paq.push(['setTrackerUrl', u + 'matomo.php']);
  //   _paq.push(['setSiteId', '1']);
  //   var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
  //   g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
  // })();

  // Google
  window.dataLayer = window.dataLayer || [];
  gtag('js', new Date());
  gtag('config', 'G-PHNFGFKKRK');
  const script = document.createElement('script');
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-PHNFGFKKRK';
  document.head.append(script);
}


export default function GDPR() {
  const [shouldShow, setShouldShow] = useState(false);

  function markAccepted() {
    db.kv.put({ key: 'gdpr', accepted: true });
    setShouldShow(false);
    onGDPRAccepted();
  }

  useEffect(() => {
    (async () => {
      const count = await db.kv.count();
      const val = await db.kv.get({ key: 'gdpr' });
      const newShouldShow = !val || !val.accepted;
      setShouldShow(newShouldShow);
      if (!newShouldShow) {
        onGDPRAccepted();
      }
    })();
  }, []);
  if (!shouldShow) {
    return null;
  }
  return <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, minHeight: 50, py: 1, px: 1 }} elevation={3}>
    <Container maxWidth={MAX_WIDTH}>
      <Toolbar>
        <Typography>We use cookies to improve user experience, improve our offerings, and analyze website and app traffic. For these reasons we may share your usage data with our analytics partners.
          By clicking "Accept" you agree to this as outlined in our <a href="/cookie-policy" target="_blank" >Cookie Policy</a>.</Typography> <div> <Button variant='contained' onClick={() => markAccepted()}>Accept</Button></div>
      </Toolbar>
    </Container>
  </Paper >;
}