import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { HashRouter as Router, Link, LinkProps } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import WorkIcon from '@mui/icons-material/Work';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CheckIcon from '@mui/icons-material/Check';

import FileDropzone from '../Components/FileDropzone';
import { Autocomplete, Chip, FormControl, FormLabel, Radio, RadioGroup } from '@mui/material';


const Confirmation = React.forwardRef((props, ref) => {
  return <Container component="main" maxWidth="xs" style={props.style}>
    <Box
      sx={{
        // marginTop: 8 ,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Avatar
        sx={{ m: 1, bgcolor: 'primary.main' }}
      >
        <CheckIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Done
      </Typography>
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" align="center" color="text.secondary" paragraph>
              Your referral requests were sent to people at the companies you requested.<br />
              <br />
              <Button variant="outlined" href="mailto:joe@upwardline.com?subject=I%20want%20to%20refer%20people&body=Hi%20Joe%2C%0A%0AI%20work%20at....%0AAnd%20I%20want%20to%20help%20refer%20people">Want to help refer people?</Button>
            </Typography>

          </Grid>
        </Grid>
      </Box>
    </Box>
  </Container>;
});

export default Confirmation;