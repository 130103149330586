import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Container, CssBaseline, Typography, StyledEngineProvider } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Index from './components/Screens/Index';
import { red } from '@mui/material/colors';
import ReferralForm from './components/Screens/ReferralForm';
import { ReactLazyAndPreload } from './lib/util/react';
import { DEV } from './env/env';
import GDPR from './components/Components/GDPR';

const CookiePolicy = React.lazy(() => import('./components/Screens/CookiePolicy'));
const TermsOfUse = React.lazy(() => import('./components/Screens/TermsOfUse'));
const PrivacyPolicy = React.lazy(() => import('./components/Screens/PrivacyPolicy'));

// import themes from 'themes';
// import ReferralForm from './components/Screens/ReferralForm';
// const ReferralForm = ReactLazyAndPreload(() => import('./components/Screens/ReferralForm'));

if (!DEV && window.location.protocol !== "https:") {
  window.location.protocol = "https:";
}


function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: red[500],
      },
    },
  });
  return (
    <React.Suspense fallback={<></>}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Index />} />
            <Route path="/get-referred" element={<ReferralForm />} >
              <Route path=":step" element={<ReferralForm />} />
            </Route>
            <Route path="/cookie-policy" element={<CookiePolicy />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
          </Routes>
        </BrowserRouter>
        <GDPR />
      </StyledEngineProvider>
    </React.Suspense>
  );
}

export default App;
