// Next ID: 11
const COMPANIES = [
  // {
  //   "id": 4,
  //   "name": "AT&T"
  // },
  {
    "id": 9,
    "name": "ByteDance (TikTok)"
  },
  {
    "id": 6,
    "name": "Deloitte"
  },
  // {
  //   "id": 7,
  //   "name": "Indeed"
  // },
  {
    "id": 1,
    "name": "Instacart"
  },
  {
    "id": 3,
    "name": "Fox"
  },

  // {
  //   "id": 2,
  //   "name": "Google"
  // },
  // {
  //   "id": 5,
  //   "name": "Sirius XM (incl. Pandora and Stitcher)"
  // },
  {
    "id": 10,
    "name": "Walmart"
  },
  {
    "id": 8,
    "name": "ZS Associates"
  },

];

module.exports = { COMPANIES };