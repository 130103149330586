import React from 'react';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import CameraIcon from '@mui/icons-material/PhotoCamera';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { HashRouter as Router, Link, LinkProps } from "react-router-dom";
import Typewriter from 'typewriter-effect';
import {
  Avatar, CardHeader, IconButton, Link as MuiLink, Paper,
} from '@mui/material';
import friends from '../../assets/img/toa-heftiba-x9I-6yoXrXE-unsplash.s.jpg';
import joePhoto from '../../assets/img/20191108_082548-modified.png';
import productHuntLogo from '../../assets/img/product-hunt-logo-vertical-orange.png';
import { useTheme } from '@mui/material/styles';
import Navbar from '../Navbars/Navbar';
import Footer from '../Footers/Footer';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import FastForwardIcon from '@mui/icons-material/FastForward';
const { MAX_WIDTH } = require('../../env/env');


export default function Index() {
  return <>
    <Paper
      sx={{
        position: 'relative',
        backgroundColor: 'grey.800',
        color: '#fff',
        mb: 1,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${friends})`,
      }}
    >
      <Navbar />
      <Box
        sx={{
          // bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
          justifyContent: 'center'
        }}
      >
        <Container maxWidth={MAX_WIDTH}>
          <Typography
            component="h1"
            variant="h1 "
            align="center"
            className='slight-shadow'
            // color="text.primary"
            gutterBottom
          >
            Your Friend At <Typewriter
              onInit={(typewriter) => {
                typewriter
                  // .typeString('Google')
                  // .deleteAll()
                  // .typeString('Facebook')
                  // .deleteAll()
                  .typeString('Instacart')
                  .deleteAll()
                  .typeString('Fox')
                  .deleteAll()
                  .typeString('Your Dream Job')
                  .callFunction((state) => {
                    // turn off animation
                    state.elements.cursor.style.animation = 'none';
                    // hide cursor
                    state.elements.cursor.style.display = 'none';
                  })
                  .start();
              }} />
          </Typography>
          <Typography variant="h6" align="center"
            // color="text.secondary"
            className='slight-shadow'
            paragraph>
            Stop applying to jobs and letting the algorithm decide if you're considered.  Get referred to your dream job by a real person who really works there.
          </Typography>
          <Stack
            sx={{ pt: 4 }}
            direction="row"
            spacing={2}
            justifyContent="center"
          >
            <Button variant="contained" component={Link} to="/get-referred" sx={{ width: 'md' }}>Start</Button>
            <Button variant="outlined" style={{ backgroundColor: 'white' }} to="#how">Learn More</Button>

          </Stack>
        </Container>
      </Box>
    </Paper>
    {/*  <Box sx={{ flexGrow: 1 }}>
      <Toolbar>
        <Typography variant="h6" style={{ color: 'inherit', textDecoration: 'inherit' }} component={Link} to="/" sx={{ flexGrow: 1 }}>
          Your Friend At
        </Typography>
        <Button to="mailto:joe@upwardline.com?subject=Your%20Friend%20At%20Feedback&body=Hi%20Joe%2C%0A%0AI%20just%20wanted%20to%20let%20you%20know..." >Feedback</Button>
      </Toolbar>
    </Box>
    <Box
      sx={{
        bgcolor: 'background.paper',
        pt: 8,
        pb: 6,
        justifyContent: 'center'
      }}
    >
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h1 "
          align="center"
          color="text.primary"
          gutterBottom
        >
          Your Friend At <Typewriter
            onInit={(typewriter) => {
              typewriter
                // .typeString('Google')
                // .deleteAll()
                // .typeString('Facebook')
                .deleteAll()
                .typeString('Instacart')
                .deleteAll()
                .typeString('Fox')
                .deleteAll()
                .typeString('Your Dream Job')
                .callFunction((state) => {
                  // turn off animation
                  state.elements.cursor.style.animation = 'none';
                  // hide cursor
                  state.elements.cursor.style.display = 'none';
                })
                .start();
            }} />
        </Typography>
        <Typography variant="h6" align="center" color="text.secondary" paragraph>
          Stop applying to jobs and letting the algorithm decide if you're considered.  Get referred to your dream job by a real person who really works there.
        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button variant="contained" component={Link} to="/get-referred" sx={{ width: 'md' }}>Start</Button>
          <Button variant="outlined">Learn More</Button>

        </Stack> */}
    <Container maxWidth={MAX_WIDTH}>
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="text.primary"
        gutterBottom
        id="how"
      >
        How it works
      </Typography>
      <Grid container spacing={3} alignItems="stretch">
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
        >
          <Card style={{
            height: '100%',
            // background: `linear-gradient(210.04deg, blue -50.94%, rgba(144, 202, 249, 0) 83.49%)`,
          }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  mb: 2,
                }}
              >
                <Avatar
                  sx={{ m: 1, bgcolor: 'primary.main' }}
                >
                  <QuestionMarkIcon />
                </Avatar>


                <Typography component="h2" variant="h4" color="text.primary">
                  Request
                </Typography>
              </Box>
              Let us know which companies you're interested in.
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          alignItems="stretch"
        >
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  mb: 2,
                }}
              >
                <Avatar
                  sx={{ m: 1, bgcolor: 'primary.main' }}
                >
                  <ManageSearchIcon />
                </Avatar>

                <Typography component="h2" variant="h4" color="text.primary">
                  Review
                </Typography>
              </Box>
              Real people at those companies will review the information you provide.
            </CardContent>
          </Card>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          alignItems="stretch"
        >
          <Card style={{ height: '100%' }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  mb: 2,
                }}
              >
                <Avatar
                  sx={{ m: 1, bgcolor: 'primary.main' }}
                >
                  <FastForwardIcon />
                </Avatar>

                <Typography component="h2" variant="h4" color="text.primary">
                  Refer
                </Typography>
              </Box>
              If they're comfortable, they'll refer you, pushing your job application to the front of the line.
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
    <Box

      sx={{
        py: 3,
        px: 3,
        mt: 3,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800],
      }}
    >
      <Container id="about"
        maxWidth={MAX_WIDTH} style={{
          justifyContent: 'center'
        }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          <img src={joePhoto} style={{ height: 200 }} /><br />
          About
        </Typography>
        <Typography variant="h6" align="center"
          //  color="text.secondary"
          paragraph>
          Hi, I'm Joe. I'm a hiring manager at Instacart who knows how hard it can be to find and hire the right candidates. Before that I was a software engineer who was looking for the right job for me.
        </Typography>
        <Typography variant="h6" align="center"
          //  color="text.secondary"
          paragraph>
          In both cases referrals have been incredibly powerful in getting the job done. I hope this site helps you achieve your career goals as well. There's nothing more powerful than people helping people.
          {/* <br />
          <br />
          <Link to="https://www.linkedin.com/in/iamjoecohen/" target="_blank">
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="rgb(25, 118, 210)" viewBox="0 0 16 16">
              <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
            </svg>
          </Link> */}
        </Typography>
        {/* <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        > */}

        {/* </Stack> */}
      </Container>
    </Box>
    <Box

      sx={{
        // py: 3,
        // px: 2,
        mt: 2,
        // backgroundColor: (theme) =>
        //   theme.palette.mode === 'light'
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[800],
      }}
    >
      <Container id="pricing"
        maxWidth={MAX_WIDTH} style={{
          justifyContent: 'center'
        }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Pricing
        </Typography>
        <Typography variant="h6" align="center"
          //  color="text.secondary"
          paragraph>
          This site is <b>free</b>. There's no charge to be referred or to refer people. If you're a company that wants to be featured we can <a href="mailto:joe@upwardline.com?subject=Sponsorship&body=Hi%20Joe%2C%0A%0AI'd%20like%20to%20sponsor%20YourFriendAt.com.">discuss a sponsorship</a>. But you cannot pay to be referred to a job. I want to help you get paid.
        </Typography>
        <Stack
          sx={{ pt: 4 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button variant="contained" component={Link} to="/get-referred" sx={{ width: 'md' }}>Get Started</Button>

          {/* <Button align="center" variant="contained" component={Link} to="/get-referred" sx={{ width: 'md' }}>Get Started</Button> */}
        </Stack>
      </Container>
    </Box>
    <Box

      sx={{
        py: 3,
        px: 3,
        mt: 3,
        backgroundColor: (theme) =>
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800],
      }}
    >
      <Container id="safety"
        maxWidth={MAX_WIDTH} style={{
          justifyContent: 'center'
        }}>
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="text.primary"
          gutterBottom
        >
          Safety
        </Typography>
        <Typography variant="h6" align="center"
          //  color="text.secondary"
          paragraph>
          This is the internet. I do my best to protect your information by only sending resumes to people with emails associated with the company you're requesting referral to. But you can protect yourself further by getting a dedicated email and Google voice or other number for your job hunt, and ensuring that all communication you receive about your referral comes from someone at the company.
        </Typography>
        <Typography variant="h6" align="center"
          //  color="text.secondary"
          paragraph>
          If someone who should be referring you does something suspicious, please <a href="mailto:joe@upwardline.com?subject=Report%20a%20Referrer&body=Hi%20Joe%2C%0A%0AI%20requested%20a%20referral%20to%3A%20%0AThe%20person%20who%20is%20supposed%20to%20refer%20me%20is%3A%20%0ATheir%20email%20is%3A%20%0AThey're%20doing%20something%20which%20leads%20me%20to%20believe%20they%20may%20not%20be%20legitimate%3A%0A%0A">report them</a>.
        </Typography><Typography variant="h6" align="center"
          //  color="text.secondary"
          paragraph>
          Together we can get you your dream job quickly and safely.
        </Typography>
        {/* <Stack
    sx={{ pt: 4 }}
    direction="row"
    spacing={2}
    justifyContent="center"
  > */}

        {/* </Stack> */}
      </Container>
    </Box>

    <Box

      sx={{
        // py: 3,
        // px: 2,
        mt: 2,
        // backgroundColor: (theme) =>
        //   theme.palette.mode === 'light'
        //     ? theme.palette.grey[100]
        //     : theme.palette.grey[800],
      }}
    >
      <Container sx={{ mt: 2 }} maxWidth={MAX_WIDTH}>
        <div
          align="center"
        >
          <MuiLink target="_blank" href="https://www.producthunt.com/posts/your-friend-at">
            <img style={{ height: 100 }} src={productHuntLogo} />
          </MuiLink>
        </div>
      </Container>
    </Box>
    <Footer />
    {/* </Container>
    </Box > */}
  </>;
}