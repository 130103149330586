import { Button, Container, IconButton, Link as MuiLink, Menu, MenuItem, SwipeableDrawer, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { HashRouter as Router, Link, LinkProps } from "react-router-dom";
import React from 'react';
import { HAMBURGER_MENU_SIZE, MAX_WIDTH } from './../../env/env';



export default function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(HAMBURGER_MENU_SIZE));

  const [open, setOpen] = React.useState(false);

  const menu = [
    { label: 'How it Works', href: '/#how' },
    { label: 'About', href: '/#about' },
    { label: 'Pricing', href: '/#pricing' },
    { label: 'Feedback', href: 'mailto:joe@upwardline.com?subject=Your%20Friend%20At%20Feedback&body=Hi%20Joe%2C%0A%0AI%20just%20wanted%20to%20let%20you%20know...' }
  ];

  return <Box
    sx={{
      justifyContent: 'center'
    }}
  >
    <Container maxWidth={MAX_WIDTH}>
      <Toolbar>
        <Typography variant="h6" style={{ color: 'inherit', textDecoration: 'inherit' }} component={Link} to="/" sx={{ flexGrow: 1 }}>
          Your Friend At
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              // color="textPrimary"
              // className={classes.menuButton}
              style={{ color: 'inherit' }}
              edge="start"
              aria-label="menu"
              onClick={() => setOpen(!open)}
            >
              <MenuIcon />
            </IconButton>

          </>
        ) : (<>
          {menu.map(item => <Button LinkComponent={item.href ? MuiLink : Link} key={item.label} style={{ color: 'inherit' }} href={item.href} to={item.to} onClick={() => setOpen(false)} >{item.label}</Button>)}
        </>
        )}
      </Toolbar>
    </Container >
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      PaperProps={{
        sx: { width: "100%" },
      }}    >
      <Box textAlign='end' sx={{ paddingRight: 1 }}
      >
        <IconButton
          align="center"
          // color="textPrimary"
          // className={classes.menuButton}
          style={{ color: 'inherit' }}
          edge="end"
          aria-label="menu"
          onClick={() => setOpen(false)}
          disableFocusRipple
          disableRipple
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {menu.map(item => <Button LinkComponent={item.href ? MuiLink : Link} key={item.label} style={{ color: 'inherit' }} href={item.href} to={item.to} onClick={() => setOpen(false)} >{item.label}</Button>)}
    </SwipeableDrawer>
  </Box >;
}
